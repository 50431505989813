import moment from 'moment';
import numeral from 'numeral';
import clone from 'clone';
import APP_TYPE from '../constants/schriftsatz.application.type';
import { NOT_TRIM_STR } from '../constants/js/schriftsatz.constants';

/**
 * create a new Reihung by item list (Beteiligte, Dokumente)
 * @param {Array} itemList itemList
 * @param {String} itemKey itemKey
 * @return {number} reihung
 */
export function createItemKey(itemList, itemKey = 'reihung') {
  let retval = 0;
  if (itemList.length === 0) {
    retval = 1;
  } else {
    retval = itemList[0][itemKey];
    for (let i = 0; i < itemList.length; i += 1) {
      if (retval < itemList[i][itemKey]) {
        retval = itemList[i][itemKey];
      }
    }
    retval += 1;
  }
  return retval;
}

/**
 * is gerichtshoefe
 * @param {String} applikationType applikationType
 * @return {boolean} is gerichtshoefe
 */
export function isGerichtshoefe(applikationType) {
  return applikationType === APP_TYPE.VF
    || applikationType === APP_TYPE.VW
    || applikationType === APP_TYPE.BW;
}

/**
 * substring function
 * @param {String} str string
 * @param {Number} number number
 * @return {*|string|*} new string
 */
export function subString(str, number = 99999999) {
  return (str && str.length > number) ? `${str.substring(0, number)}...` : str;
}

/**
 * get new options from all options and without existed options
 * @param {Array} options options
 * @param {Array} existOptions existOptions
 * @param {String} checkValue check value
 * @return {Array} new options
 */
export function getNewOptions(options, existOptions, checkValue = 'value') {
  let newOptions = [];
  if (options && existOptions && existOptions.length > 0) {
    options.forEach((o) => {
      let exist = false;
      existOptions.forEach((e) => {
        if (e && o && e[checkValue] === o.value) {
          exist = true;
        }
      });
      if (!exist) {
        newOptions.push(o);
      }
    });
  } else {
    newOptions = options;
  }
  return newOptions;
}

/**
 * get properties by it's path from object
 * @param {Object} object object
 * @param {String} propPath propPath
 * @return {Object} propertie
 */
export function getProp(object, propPath = '') {
  let prop = null;
  if (object) {
    const split = propPath.split('.');
    if (split.length > 1) {
      prop = object[split[0]];
      for (let i = 1; i < split.length; i += 1) {
        if (prop) {
          prop = prop[split[i]];
        } else {
          break;
        }
      }
    } else {
      prop = object[propPath];
    }
  }
  return prop;
}

/**
 * get properties by it's path from object by prop name
 * @param {Object} object object
 * @param {String} name prop name
 * @return {Object} propertie
 */
export function getPropByName(object, name) {
  if (object && name) {
    Object.keys(object).forEach((key) => { // eslint-disable-line
      if (object[key] === name) {
        return object[key];
      }
    });
  }
  return null;
}

/**
 * check if a array has element
 * @param {Array} array array
 * @return {Boolean} yes or no
 */
export function arrayHasItem(array) {
  return array && Array.isArray(array) && array.length > 0;
}

/**
 * check if a array has element
 * @param {Array} array array
 * @return {Boolean} yes or no
 */
export function arrayHasOneItem(array) {
  return array && Array.isArray(array) && array.length > 0 && array.length === 1;
}

/**
 * returnArray
 * @param {Array} array array
 * @return {*|*[]} array
 */
export function returnArray(array) {
  return arrayHasItem(array) ? array : [];
}

/**
 * check if a array has element
 * @param {Array} array array
 * @return {Boolean} yes or no
 */
export function emptyArray(array) {
  return array && Array.isArray(array) && array.length === 0;
}

/**
 *  check if an object has a parameter
 * @param {Object} object object
 * @param {String} paramName paramName
 * @return {Boolean} yes or no
 */
export function hasParam(object, paramName = 'id') {
  return (object && object[paramName]) || false;
}

/**
 * capitalize function
 * @param {string} text text
 * @returns {string} text
 */
export function capitalize(text) {
  return isString(text) ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : text;
}

/**
 * convertFromGMTDateToString function
 * @param {Object} datum datum
 * @returns {string} date in string
 */
export function convertFromGMTDateToString(datum) {
  if (datum) {
    return (moment(datum).isValid() ? moment(datum).format('DD.MM.YYYY') : datum);
  }
  return '';
}

/**
 * convertFromGMTDateToString function, date format is DD.MM.YYYY HH:MM:SS
 * @param {Object} datum datum
 * @returns {string} date in string
 */
export function convertFromGMTDateWithTimeToString(datum) {
  if (datum) {
    return moment(datum).isValid() ? moment(datum).format('DD.MM.YYYY, HH:mm:ss') : datum;
  }
  return '';
}

/**
 * convertFromGMTDateToString function, date format is DD.MM.YYYY HH:MM:SS
 * @param {Object} datum datum
 * @returns {string} date in string
 */
export function convertFromGMTDateWithTimeToString2(datum) {
  if (datum) {
    return moment(datum).isValid() ? moment(datum).format('DD.MM.YYYY (HH:mm:ss)') : datum;
  }
  return '';
}

/**
 * convert euro to string
 * @param {number} euro euro
 * @return {string} euro string
 */
export function convertEuroToString(euro) {
  return `${numeral(euro).format('0,0[.]00')}  €`;
}

/**
 * remove a item from array
 * @param {Array} array array
 * @param {Object} item item
 * @return {Array} new Array
 */
export function removeItemOfArray(array, item) {
  return (array && item) ? array.filter((v) => v !== item) : array;
}

/**
 * json to array
 * @param {object} json json
 * @return {Array} array
 */
export function jsonToArray(json) {
  const retval = [];
  Object.keys(json)
    .forEach((key) => {
      retval.push({
        value: key,
        label: json[key],
      });
    });
  return retval;
}

/**
 * check if a object is empty
 * @param {Object} obj object
 * @return {boolean} yes or no
 */
export function isEmptyObj(obj) { // eslint-disable-line

  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (let key in obj) { // eslint-disable-line
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

/**
 * adds an update action to update multiple form fields
 * @param {Object} settings settings for async select
 * @param {Function} action update action for form fields
 * @param {Function} interceptOption intercept select option
 * @param {Function} interceptQueryParams intercept search params
 * @returns {Object} updated object
 */
export function addActionToAsyncSettings(settings, action, interceptOption, interceptQueryParams) {
  const updatedSettings = settings;
  updatedSettings.updateAdditionalFields.action = action;
  updatedSettings.updateAdditionalFields.interceptOption = interceptOption;
  updatedSettings.updateAdditionalFields.interceptQueryParams = interceptQueryParams;
  return updatedSettings;
}

/**
 * trim object
 * @param {Object} formValues formValues
 * @return {undefined}
 */
export function trimObject(formValues) {
  if (formValues) {
    for (let key in formValues) { // eslint-disable-line
      const value = formValues[key];
      if (Object.prototype.toString.call(value) !== '[object File]') {
        if (value instanceof Object) {
          trimObject(value);
        } else if (isString(value) && value.includes(NOT_TRIM_STR)) {
          formValues[key] = value.replace(NOT_TRIM_STR, '');
        } else if (isString(value) && !value.includes('.pdf') && value.indexOf(' ') !== -1 && !value.includes(NOT_TRIM_STR)) {
          formValues[key] = trimStr(value);
        } else if (isString(value) && value === '' && !value.includes(NOT_TRIM_STR)) {
          formValues[key] = null;
        }
      }
    }
  }
}

/**
 * trim function
 * @param {String} str string
 * @return {*} new string
 */
export function trimStr(str) {
  let temp = str.replace(/^\s*/, '');
  for (let i = temp.length - 1; i >= 0; i -= 1) {
    if (/\S/.test(temp.charAt(i))) {
      temp = temp.substring(0, i + 1);
      break;
    }
  }
  return temp;
}

/**
 * get now
 * @return {string} now
 */
export function getNow() {
  return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * check if it is a integer
 * @param {Number} value value
 * @return {Number} value
 */
export function getInteger(value) {
  if (value) {
    return Number.isInteger(value) ? value : 0;
  }
  return 0;
}

/**
 * replace all
 * @param {String} string original string
 * @param {String} find string
 * @param {String} replace string
 * @return {*} changed string
 */
export function replaceAll(string, find, replace) {
  return (string && string.replace(new RegExp(find, 'g'), replace)) || string;
}

/**
 * range
 * @param {Number} start start
 * @param {Number} end end
 * @param {Number} step step
 * @return {[]} array
 */
export function range(start, end, step = 1) {
  const retval = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    retval.push(i);
  }
  return retval;
}

/**
 * isIE
 * @return {boolean} yes or no
 */
export function isIE() {
  return !!window.ActiveXObject || 'ActiveXObject' in window;
}

/**
 * convertNullToBlank
 * @param {Array} list list
 * @return {*} list
 */
export function convertNullToBlank(list) {
  if (arrayHasItem(list)) {
    for (let i = 0; i < list.length; i += 1) {
      if (list[i] === null) {
        list.splice(i, 1, '');
      }
    }
  }
  return list;
}

/**
 * isString
 * @param {String} str string
 * @return {boolean} yes or no
 */
export function isString(str) {
  return typeof str === 'string';
}

/**
 * isNumber
 * @param {Number} num num
 * @return {boolean} yes or no
 */
export function isNumber(num) {
  return Number.isInteger(num);
}

/**
 * change value
 * @param {Object} event event
 * @return {undefined}
 */
export function onUseEnterAsTab(event) {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
}

/**
 * isKeyDown
 * @param {Object} event event
 * @returns {boolean} yes or no
 */
export function isKeyDown(event) {
  return event && (event.code === 'Enter' || event.keyCode === 'KEYCODE_ENTER' || event.keyCode === 13);
}

/**
 * equalsArrays
 * @param {Array} arrA arrA
 * @param {Array} arrB arrB
 * @returns {boolean} yes or no
 */
export function equalsArrays(arrA, arrB) {
  if (!arrA && arrayHasItem(arrB)) {
    return false;
  }
  if (arrayHasItem(arrA) && !arrB) {
    return false;
  }
  if (!arrA && !arrB) {
    return true;
  }
  if (arrA.length !== arrB.length) {
    return false;
  }

  const m = clone(arrA).sort((a, b) => a.toString().localeCompare(b.toString()));
  const n = clone(arrB).sort((a, b) => a.toString().localeCompare(b.toString()));

  for (let i = 0; i < m.length; i += 1) {
    if (m[i] !== n[i]) {
      return false;
    }
  }
  return true;
}

/**
 * groupStr
 * @param {String} str str
 * @param {Number} step step
 * @returns {*[]} groupped string
 */
export function groupStr(str, step) {
  const retval = [];

  // eslint-disable-next-line require-jsdoc
  function doGroup(s) {
    if (!s) return;
    retval.push(s.substr(0, step));
    s = s.substr(step);
    doGroup(s);
  }

  doGroup(str);
  return retval;
}

/**
 * reRowTextAreaContentInString
 * @param {String} value value
 * @param {Number} maxLength maxLength
 * @param {Number} maxRows maxRows
 * @returns {string} new value
 */
export function reRowTextAreaContentInStringByMaxLength(value, maxLength, maxRows) { // eslint-disable-line
  const textAreaRows = value.split('\n');

  const newTextAreaRows = [];

  if (arrayHasItem(textAreaRows)) {
    for (let idxRow = 0; idxRow < textAreaRows.length; idxRow += 1) {
      const currentRow = textAreaRows[idxRow];
      let next = null;

      if ((idxRow + 1) < textAreaRows.length) {
        next = textAreaRows[idxRow + 1];
      }


      // should be splice
      if (maxLength && currentRow.length > maxLength) {
        const groupsOfCurrentRow = groupStr(currentRow, maxLength);

        for (let idxGroup = 0; idxGroup < groupsOfCurrentRow.length; idxGroup += 1) {
          const currentGroup = groupsOfCurrentRow[idxGroup];

          newTextAreaRows.push(currentGroup);

          if (currentGroup && idxGroup !== groupsOfCurrentRow.length - 1) {
            newTextAreaRows.push('\n');
          }

          if (next && (idxGroup === groupsOfCurrentRow.length - 1)) {
            newTextAreaRows.push('\n');
          }
        }
      } // eslint-disable-line


      // not splice
      else {
        newTextAreaRows.push(currentRow);

        if (idxRow !== textAreaRows.length - 1) {
          newTextAreaRows.push('\n');
        }
      }
    }
  }

  let newTextAreaInString = '';
  for (let i = 0; i < newTextAreaRows.length; i += 1) {
    newTextAreaInString += newTextAreaRows[i];
  }

  return newTextAreaInString;
}

/**
 * unique
 * @param {Array} array array
 * @return {any[]} array
 */
export function unique(array) {
  return Array.from(new Set(array));
}

/**
 * isUndefined
 * @param {String} value value
 * @return {boolean} isUndefined
 */
export function isUndefined(value) {
  return value === undefined || value === null || value === 'undefined' || value === 'null';
}

/**
 * splitByLength
 * @param {String} str str
 * @param {Number} length length
 * @return {*|*[]} splitByLength
 */
export function splitByLength(str, length = 999999) {
  if (str) {
    const retval = [];
    for (let i = 0; i < str.length; i += length) {
      retval.push(str.slice(i, i + length));
    }
    return retval;
  }
  return str;
}

/**
 * getReadableFileSizeString
 * @param {number} fileSizeInBytes fileSizeInBytes
 * @param {Number} fractionDigits fractionDigits
 * @return {string} getReadableFileSizeString
 */
export function getReadableFileSizeString(fileSizeInBytes, fractionDigits = 0) {
  if (fileSizeInBytes) {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes /= 1024;
      i += 1;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(fractionDigits) + byteUnits[i];
  }
  return fileSizeInBytes;
}

/**
 * deleteLastChar
 * @param {String} str str
 * @param {String} char char
 * @return {string} deleteLastChar
 */
export function deleteLastChar(str, char) {
  if (str && str.substr(str.length - 1, 1) === char) {
    str = str.substr(0, str.length - 1);
  }
  return str;
}

/**
 * deleteLastCharByHeader
 * @param {String} str str
 * @return {string} deleteLastChar
 */
export function deleteLastCharByHeader(str) {
  if (str) {
    str = subString(str, 160);
    str = trimStr(str);
    str = deleteLastChar(str, ',');
    str = deleteLastChar(str, ';');
  }
  return str;
}

/**
 * getTextAreaRowLength
 * @param {Array} str str
 * @return {number|*} getTextAreaRowLength
 */
export function getTextAreaRowLength(str) {
  if (str) {
    if (arrayHasItem(str)) {
      return str.length;
    }
    if (emptyArray(str)) {
      return 0;
    }
    return str.split('\n').length;
  }
  return 0;
}

/**
 * Removes all occurrences of newline, tab, carriage return, and non-breaking space characters from the given string.
 *
 * @param {string} str - The input string from which characters will be removed.
 * @return {string} The cleaned string with the specified characters removed. If the input is falsy, it returns the input as is.
 */
export function removeEnterString(str) {
  let retval = str;
  if (retval) {
    retval = _removeString(retval, '\n');
    retval = _removeString(retval, '\t');
    retval = _removeString(retval, '\r');
    retval = _removeString(retval, '\u00A0');
  }
  return retval;
}

/**
 * Removes all occurrences of a specified character from the given string.
 *
 * @param {string} str - The original string from which the character will be removed.
 * @param {string} char - The character to be removed from the string.
 * @return {string} The modified string with all occurrences of the specified character removed. If the character is not found, the original string is returned. If the input string or character is invalid, the original string is returned.
 */
function _removeString(str, char) {
  if (str && char) {
    if (str.includes(char)) {
      let retval = '';
      // eslint-disable-next-line no-return-assign
      str.split(char).forEach((c) => retval += c);
      return retval;
    }
  }
  return str;
}

/**
 * Evaluates a boolean value. If the value is explicitly false or true,
 * returns false. For any other input, returns true.
 *
 * @param {boolean|null|undefined} value - The value to be evaluated.
 * @return {boolean} False if the value is true or false, otherwise true.
 */
export function nullBoolean(value) {
  return !(value === false || value === true);
}

/**
 * Determines if the first date is the same or later than the second date.
 *
 * @param {string|Date} date1 - The first date to compare. It can be a string or a Date object.
 * @param {string|Date} date2 - The second date to compare. It can be a string or a Date object.
 * @return {boolean} Returns true if the first date is the same or later than the second date, otherwise returns false.
 */
export function afterDate(date1, date2) {
  return new Date(date1) >= new Date(date2);
}
