export const GESETZ_25a_GGG = 'https://rdb.manz.at/document/ris.n.NOR40260995';
export const GESETZ_25_4_GGG = 'https://rdb.manz.at/document/ris.n.NOR40243697';
export const GESETZ_1Z1_GGG = 'https://rdb.manz.at/document/ris.n.NOR40243698';
export const GESETZ_1Z2_GGG = 'https://rdb.manz.at/document/ris.n.NOR40243698';
export const GESETZ_53_GBG = 'https://rdb.manz.at/document/ris.n.NOR40138274';
export const GESETZ_53_WFG = 'https://rdb.manz.at/document/ris.n.NOR40023402';
export const GESETZ_42_WSG = 'https://rdb.manz.at/document/ris.n.NOR12149477';
export const GESETZ_15_3_AGRVG = 'https://rdb.manz.at/document/ris.n.NOR40007761';
export const GESETZ_34_BBG = 'https://rdb.manz.at/document/ris.n.NOR30002140?source=726462233230323330333033237269732e632e4247424c5f4f535f32303030313232395f315f3134322352534c2334313937323637383032';
export const GESETZ_NEU_FOEG = 'https://rdb.manz.at/document/ris.n.NOR30002093';
export const GESETZ_6B_3_GUG = 'https://rdb.manz.at/document/ris.c.BGBl__I_Nr__91_2024';
export const GESETZ_12_ERV_2021_2 = 'https://rdb.manz.at/document/ris.n.NOR40240405';
export const GESETZ_243_B_ABS_1_UGB = 'https://rdb.manz.at/document/ris.n.NOR40189008?source=6C6232303234313131390EB60263';
export const GESETZ_243_B_ABS_1_1_UGB = 'https://rdb.manz.at/document/ris.n.NOR40248503?source=6C6232303234313131390EB60263';
export const GESETZ_277_ABS_6_UGB = 'https://rdb.manz.at/document/ris.n.NOR40248507?source=6C6232303234313131390EB60263';
export const GESETZ_244_UGB = 'https://rdb.manz.at/document/ris.n.NOR40188999?source=6C6232303234313131390EB60263';
export const GESETZ_267_A_ABS_1_UGB = 'https://rdb.manz.at/document/ris.n.NOR40189009?source=6C6232303234313131390EB60263';
export const GESETZ_276_C_ABS_1_1_SATZ_UGB = 'https://rdb.manz.at/document/ris.n.NOR40248506?source=6C6232303234313131390EB60263';
export const GESETZ_280_ABS_3 = 'https://rdb.manz.at/document/ris.n.NOR40248508?source=6C6232303234313132300EAF025B';
export const GESETZ_NABEG = 'https://rdb.manz.at/document/rdb.tso.LIdja20240310';
