const ALL_LETTERS = 'a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİıĲĳĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽžſƒǺǻǼǽǾǿȘșȚțẀẁẂẃẄẅỲỳŹźŻżŽž';
const ALL_FB_LETTERS = 'a-zA-Z ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİıĲĳĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽžſƀƁƂƃƄƅƆƇƈƉƊƋƌƍƎƏƐƑƒƓƔƕƖƗƘƙƚƛƜƝƞƟƠơƢƣƤƥƦƧƨƩƪƫƬƭƮƯưƱƲƳƴƵƶƷƸƹƺƻƼƽƾƿǀǁǂǃǄǅǆǇǈǉǊǋǌǍǎǏǐǑǒǓǔǕǖǗǘǙǚǛǜǝǞǟǠǡǢǣǤǥǦǧǨǩǪǫǬǭǮǯǰǱǲǳǴǵǶǷǸǹǺǻǼǽǾǿȀȁȂȃȄȅȆȇȈȉȊȋȌȍȎȏȐȑȒȓȔȕȖȗȘșȚțȜȝȞȟȠȡȢȣȤȥȦȧȨȩȪȫȬȭȮȯȰȱȲȳȴȵȶȷȸȹȺȻȼȽȾȿɀɁɂɃɄɅɆɇɈɉɊɋɌɍɎɏḀḁḂḃḄḅḆḇḈḉḊḋḌḍḎḏḐḑḒḓḔḕḖḗḘḙḚḛḜḝḞḟḠḡḢḣḤḥḦḧḨḩḪḫḬḭḮḯḰḱḲḳḴḵḶḷḸḹḺḻḼḽḾḿṀṁṂṃṄṅṆṇṈṉṊṋṌṍṎṏṐṑṒṓṔṕṖṗṘṙṚṛṜṝṞṟṠṡṢṣṤṥṦṧṨṩṪṫṬṭṮṯṰṱṲṳṴṵṶṷṸṹṺṻṼṽṾṿẀẁẂẃẄẅẆẇẈẉẊẋẌẍẎẏẐẑẒẓẔẕẖẗẘẙẚẛẜẝẞẟẠạẢảẤấẦầẨẩẪẫẬậẮắẰằẲẳẴẵẶặẸẹẺẻẼẽẾếỀềỂểỄễỆệỈỉỊịỌọỎỏỐốỒồỔổỖỗỘộỚớỜờỞởỠỡỢợỤụỦủỨứỪừỬửỮữỰựỲỳỴỵỶỷỸỹỺỻỼỽỾỿɐɑɒɓɔɕɖɗɘəɚɛɜɝɞɟɠɡɢɣɤɥɦɧɨɩɪɫɬɭɮɯɰɱɲɳɴɵɶɷɸɹɺɻɼɽɾɿʀʁʂʃʄʅʆʇʈʉʊʋʌʍʎʏʐʑʒʓʔʕʖʗʘʙʚʛʜʝʞʟʠʡʢʣʤʥʦʧʨʩʪʫʬʭʮʯ';

export const VALID_REGEX = {
  vertretungsart: '^[A-Z]*$',
  iban: '^[0-9A-Z]{5,34}$',
  bicswift: '^[0-9A-Z]{8,11}$',
  pruefsummer: '^[0-9A-Fa-f]{2}$',
  currency: '^[0-9]+(.[0-9]{1,2})?$',
  advmCode: '^([a-zA-Z]|JB)[0-9]+$',
  percent: '^[0-9]{1,2}(.[0-9]{1,2})?$',
  zustaendigkeitsPLZ: '^[1-9][0-9]{3}$',
  justizboxUploadFilename: '^[a-zA-Z0-9-_.]*$',
  vj: '[a-zA-Z0-9äöüßÄÖÜ .(+!;*)-/%&_?:§="°@,#\'$^]',
  email: `^[0-9${ALL_LETTERS}._%+-]+@[0-9${ALL_LETTERS}.-]+\\.[0-9${ALL_LETTERS}]{2,4}$`,
  sta: `[0-9 ${ALL_LETTERS}!$&"(),;\\[%\\]{}*+-.:=?@^~¡¢£¤¥¦§¨©ª«¬­®¯°±²³µ¶·¸¹º»¼½¾¿×÷€™<>\\'_/#|]`,
  bmiAktenzeichenText: `[0-9 ${ALL_LETTERS}!$&"(),;\\[%\\]{}*+-.:=?@^~¡¢£¤¥¦§¨©ª«¬­®¯°±²³µ¶·¸¹º»¼½¾¿×÷€™<>\\'_/#]`,

  thbString: `[0-9${ALL_LETTERS} .'-/%!,;:=§€$_()\\[\\]{}+*-?<>#~&"|^@\\\\]`,

  gbLiteral: '^[a-z]{1,3}$',
  gbVorgangsnummer: '^[0-9]{12}[_][0-9]{6}$',
  gbVorgemerktenEigentuemer: '^[a-z]{1,3}(,[a-z]{1,3})*$',
  gbGeschaeftsfallNummer: '^[0-9]{1,6}/[0-9]{4}/[0-9]{2}$',
  gbGrundstueck: '^\\.?[1-9][0-9]{0,4}(/[1-9][0-9]{0,4})?$',
  gbRangordnungsCode: '^([2-9A-HJ-NP-Z]{4}-){5}[2-9A-HJ-NP-Z]{3}$',
  gbUrkundenKapitel: `[0-9 ${ALL_LETTERS}'-/,;:§€$'.()\\[\\]{}?~&"^@\\\\]`,
  gb: '[0-9a-zA-ZäöüßÄÖÜ /%!,;:=§€$_()\\[\\]{}+-?<>#~&"|^@\\n\\r\\t\\\\]',
  gbEintragungZusatz: `[0-9 ${ALL_LETTERS}.\\'-/,;:§€$()\\[\\]{}?~&"|^@\\n\\r\\t\\\\]`,
  gbEintragungstext: `[0-9 ${ALL_LETTERS}.'\\- /,;:§€$'\\.\\(\\)\\[\\]\\{\\}\\?~&\\"\\^@\\\\]`,
  gbVormerkungsHinweis: '^[0-9a-zA-ZäöüßÄÖÜ /%!,;:=§€$_\'.()\\[\\]{}+*-?<>#~&"|^@\\\\]+$',
  gbToken: '[0-9a-zA-ZäöüßÄÖÜ /%!,;:=§€$_\'\\.\\(\\)\\[\\]\\{\\}\\+\\*\\-\\?<>#~&\\"\\|\\^@\\\\]',
  gbDiakritischText: `[0-9 ${ALL_LETTERS} .'\\- /%!,;:=§€$_'\\.\\(\\)\\[\\]\\{\\}\\+\\*\\-\\?<>#~&\\"\\|\\^@\\\\]`,
  gbStrukturierterText: `[0-9 ${ALL_LETTERS}.'\\- /%!,;:=§€$_'\\.\\(\\)\\[\\]\\{\\}\\+\\*\\-\\?<>#~&\\"\\|\\^@\\n\\r\\t\\\\]`,

  fbJahr: '^[0-9]{4}$',
  fbFnr: '^[0-9]{1,6} [a-z]$',
  fbGrossZiffer: '^[0-9A-Z]{1,7}$',
  fbPersonFNR: '^[0-9]{1,6} [a-z]$',
  fbPersonenkennung: '^[A-Z]{1,3}$',
  fbFirmenbuchNummer: '^[a-z0-9]{1,7}$',
  fbMitarbeiteranzahl: '^[0-9]{1,12}(.[0-9]{2})?$',
  fbPersonPNR: '^([ ]{0,2}[A-Z]{1,3})|([A-Z]{2}[ ]{0,1})$',
  fbDokumentUmsatzerloese: '^(\\+|-)?(([0-9]{1,12}(\\.[0-9]{2,2})?)|[0-9]{1,12})$',
  fbStringText: `[0-9${ALL_FB_LETTERS}€!$&"(),;\\[%\\'\\]{}*+-./:=?@|^\`~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷€™<>_#]`,
  fbVIVAStringText: `[0-9 ${ALL_FB_LETTERS}€!$&"(),;\\[%\\'\\]{}*+-./:=?@|^\`~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷€™<>_#]`,
  fbStringPersonenText: `[0-9${ALL_FB_LETTERS}€!$&"(),;\\[%\\'\\]{}*+-./:=?@|^\`~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷€™<>]`,
};
