import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ExternerLinkIcon from '../../../common/Icons/ExternerLinkIcon';

/**
 * Renders an external link as a list item.
 *
 * @param {Object} item - The item object containing properties for rendering the external link.
 * @param {string} item.label - The label or title of the external link.
 * @param {string} item.link - The URL of the external link.
 * @param {string} [item.tooltip] - An optional tooltip for the external link.
 * @return {JSX.Element} The JSX representation of a list item with an external link.
 */
function renderExternalLink(item) {
  return (
    <li key={item.label}>
      <div className="flex-space-between">
        <div className="external-link">
          <a className="manz-link-without-underline" href={item.link} target="_blank" rel="noopener noreferrer" title={item.tooltip || item.label} aria-label={item.label}><span>{item.label}</span></a>
        </div>
        <div>
          <ExternerLinkIcon
            link={item.link}
            label={item.label}
            tooltip={item.tooltip}
            className="external-link-icon"
          />
        </div>
      </div>
    </li>
  );
}

/**
 * Renders a navigation link as a list item.
 *
 * @param {Object} item - The navigation item to render.
 * @param {string} item.label - The label to display for the navigation link.
 * @param {string} item.link - The URL path to navigate to when the link is clicked.
 * @param {Function} [item.click] - Optional click handler for the navigation link.
 * @param {boolean} [item.disabled] - Optional flag to indicate if the link is disabled.
 * @param {boolean} isBurgerMenu - Indicates if the navigation link is part of a burger menu.
 * @return {JSX.Element} Returns a JSX list item containing a navigation link.
 */
function renderNavLink(item, isBurgerMenu) {
  return (
    <li key={item.label}>
      <NavLink
        exact
        to={item.link}
        onClick={item.click}
        aria-label={item.label}
        id={isBurgerMenu ? `${item.link}-burger-menu` : item.link}
        className={(!item.disabled || item.disabled === false) ? '' : 'manz-disabled'}
      >
        <span>{item.label}</span>
      </NavLink>
    </li>
  );
}

/**
 * Renders a navigation header link with optional customization for a burger menu and an icon component.
 *
 * @param {Object} item - The navigation item containing details for rendering the header.
 * @param {string} item.link - The URL path to navigate to.
 * @param {Function} item.click - The function to call when the link is clicked.
 * @param {string} item.label - The label text for the navigation item.
 * @param {boolean} isBurgerMenu - Indicates if the header should be rendered for a burger menu.
 * @param {React.Component} iconComponent - The icon component to display in the navigation header.
 * @return {React.Element} The rendered navigation header link as a React component.
 */
function renderNavHeader(item, isBurgerMenu, iconComponent) {
  return (
    <NavLink
      exact
      className=""
      to={item.link}
      onClick={item.click}
      aria-label={item.label}
      activeClassName="isActive"
      id={isBurgerMenu ? `${item.link}-burger-menu` : item.link}
    >
      {iconComponent}
      <span>{item.label}</span>
    </NavLink>
  );
}

/**
 * Renders the default navigation header with an icon component and a header title.
 *
 * @param {React.Element} iconComponent - The icon React element to be rendered as part of the header.
 * @param {string} header - The header title text to be displayed.
 * @return {React.Element} A React fragment containing the icon component and the header title.
 */
function renderDefaultNavHeader(iconComponent, header) {
  return (
    <React.Fragment> {/*eslint-disable-line*/}
      {iconComponent}
      <span>
        {header}
      </span>
    </React.Fragment>
  );
}

/**
 * NavigationGroup component
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function NavigationGroup(props) {
  const {
    items,
    header,
    isDashboard,
    isBurgerMenu,
    iconComponent,
  } = props;

  const head = isDashboard
    ? renderNavHeader(items[0], isBurgerMenu, iconComponent)
    : renderDefaultNavHeader(iconComponent, header);

  const linkItems = [];

  for (let i = 0; i < items.length; i += 1) {
    if (!isDashboard) {
      linkItems.push(items[i].external ? renderExternalLink(items[i]) : renderNavLink(items[i], isBurgerMenu));
    }
  }

  return (
    <div className="navigation-group">
      <div className="navigation-group-header">
        {head}
      </div>
      <ul className={isBurgerMenu ? 'navigation-group-items-burger-menu' : 'navigation-group-items'}>
        {linkItems}
      </ul>
    </div>
  );
}

NavigationGroup.defaultProps = {
  items: null,
  header: null,
  isDashboard: false,
};

NavigationGroup.propTypes = {
  header: PropTypes.string,
  isDashboard: PropTypes.bool,
  isBurgerMenu: PropTypes.bool.isRequired,
  iconComponent: PropTypes.element.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    click: PropTypes.func,
    link: PropTypes.string,
    label: PropTypes.string,
    external: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
  })),
};

export default NavigationGroup;
