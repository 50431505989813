import React from 'react';
import PropTypes from 'prop-types';
import print from '../../assets/icons/mz-export-externer-link.svg';

/**
 * Function
 * @param {Object} props props
 * @returns {JSX} component
 * @constructor
 */
function ExternerLinkIcon(props) {
  const {
    link,
    label,
    tooltip,
    onClick,
    className,
  } = props;

  return (
    <a
      href={link}
      target="_blank"
      aria-label={label}
      title={tooltip || label}
      className="manz-link"
      rel="noopener noreferrer"
    >
      <img // eslint-disable-line
        src={print}
        width={22}
        height={22}
        alt="new icon"
        onClick={onClick}
        id="manz-plus-circle"
        aria-label="new icon"
        className={className}
      />
    </a>
  );
}

ExternerLinkIcon.defaultProps = {
  onClick: null,
  className: null,
};

ExternerLinkIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default ExternerLinkIcon;
